<template>
  <!-- Spinner div -->
  <div class="spinner-container" :class="{ 'active': isLoading }">
    <div id="spinner" class="text-center m-5">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      Loading...
    </div>
  </div>

  <!-- Chart container -->
  <div id="chartContainer" class="p-2 chart-container" :class="{ 'active': !isLoading }" style="width: 100%; height: 400px">
  </div>
</template>

<script setup lang="ts">
import { ref, watch, toRefs, onMounted, nextTick } from 'vue';
import { getOrCreateDataObject, type DataObject, type DataItemModel } from "o365-dataobject";

const props = defineProps<{
    ids: Array<any>,
    mode: number,
    filter: string
}>();

const { ids, mode, filter } = toRefs(props);

const chartData = ref<Array<Object>>([]);
const budgetData = ref<Array<number>>([]);
const errorMessage = ref<string | null>(null); // Tracks any errors that occur
const isLoading = ref<boolean>(true);

let chart: Highcharts.Chart | null = null;

// window['chartData'] = chartData.value;

const local_projects: DataObject = getOrCreateDataObject({
    id: 'dsProjects',
    viewName: 'aviw_Portfolio_Planner',
    maxRecords: -1,
    distinctRows: false,
    fields:
    [
        { name: 'OrgUnit_ID'}
    ]
});
local_projects.enableContextFilter();
local_projects.recordSource.whereClause = filter.value;

const local_ProjectsForecasts: DataObject = getOrCreateDataObject({
    id: 'dsProjectsForecasts',
    viewName: 'aviw_Portfolio_PortfolioForecastChart',
    maxRecords: -1,
    whereClause:  ``,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields:
    [
        { name: "NameAndTitle" },
        { name: "UseCostData" },
        { name: "Year1" },
        { name: "Year2" },
        { name: "Year3" },
        { name: "Year4" },
        { name: "Year5" },
        { name: "Year6" },
        { name: "Year7" },
        { name: "Year8" },
        { name: "Year9" },
        { name: "Year10" },
        { name: "Year11" },
        { name: "Year12" },
        { name: "Year13" },
        { name: "Year14" },
        { name: "Year15" },
        { name: "Year16" },
        { name: "Year17" },
        { name: "Year18" },
        { name: "Year19" },
        { name: "Year20" },
        { name: "Year21" },
        { name: "Year22" },
        { name: "Year23" },
        { name: "Year24" },
        { name: "Year25" },
        { name: "Year26" },
        { name: "Year27" },
        { name: "Year28" },
        { name: "Year29" },
        { name: "Year30" },
    ]
});

const local_budget: DataObject = getOrCreateDataObject({
    id: 'dsBudgetChart',
    viewName: 'aviw_Portfolio_YearlyBudgets',
    maxRecords: -1,
    whereClause:  ``,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields:
    [
        { name: "OrgUnit_ID" },
        { name: "Year", },
        { name: "ForecastValuePeriodic" },
        { name: "PeriodStart" },
        { name: "PeriodEnd" }
    ]
});

// Function to load budget data
local_budget.on('DataLoaded', (data: DataObject, options) => {
    budgetData.value = [];
    data.forEach((year: DataItemModel) => {
        budgetData.value.push(year.ForecastValuePeriodic);
    })
});

// Set loading filter for the budget chart
local_budget.enableContextFilter((_, contextID) => {
    return `[OrgUnit_ID] = '${contextID}' AND [Year] >= ${new Date().getFullYear()}`;
});

const getXBudget = (x) => {
    return budgetData.value[x];
}

// Error and loading handling when creating charts
const createChart = () => {
    nextTick(() => {
        try {
            // Ensure the chart container exists before proceeding
            if (document.getElementById('chartContainer')) {
                if (chart && typeof chart.destroy === 'function') {
                    chart.destroy();
                    chart = null;
                }

        const mergedOptions = {
                    chart: {
                        type: 'column',
                        zoomType: 'xy',
                        animation: {
                            duration: 1000,
                            easing: 'easeOutBounce'
                        },
                        events: {
                            load: function () {
                                isLoading.value = false;
                            },
                            beforePrint: function () {
                                const height = this.options.exporting.chartOptions.chart.height;
                                if (height) {
                                    this.resetParams = [
                                        this.chartWidth,
                                        this.chartHeight,
                                        false
                                        ];
                                    this.setSize(this.chartWidth, height, false);
                                }
                            },
                            afterPrint: function () {
                                if (this.options.exporting.chartOptions.chart.height) {
                                    this.setSize.apply(this, this.resetParams);
                                }
                            }
                        }
                    },

                    lang: {
                        noData: {
                            style: {
                                fontWeight: 'bold',
                                fontSize: '15px',
                                color: '#303030'
                            },
                            text: 'No data to display'
                        }
                    },

                    title: {
                        text: '',
                    },

                    yAxis: {
                        title: {
                            text: $t('Budget (millions)')
                        },
                        
                        labels: {
                            formatter: function () {
                                return this.value / 1000000 + ' M';
                            }
                        },
                        stackLabels: {
                            enabled: localStorage.getItem('chartStackLabels') ? JSON.parse(localStorage.getItem('chartStackLabels')) : false,

                            formatter: function () {
                                return Highcharts.numberFormat(this.total, 0); // Format numbers to no decimal places
                            }
                        }
                    },

                    xAxis: {
                        allowDecimals: false,
                        labels: {
                            formatter: function () {
                                return this.value; // clean, unformatted number for year
                            }
                        },
                        accessibility: {
                            rangeDescription: 'Years'
                        }
                    },

                    legend: {
                        enabled: false,
                        layout: 'vertical',
                        backgroundColor: '#FFFFFF',
                        align: 'right',
                        verticalAlign: 'top',
                        floating: true,
                        y: 45,
                        navigation: {
                            activeColor: '#3E576F',
                            animation: true,
                            arrowSize: 12,
                            inactiveColor: '#CCC',
                            style: {
                                fontWeight: 'bold',
                                color: '#333',
                                fontSize: '12px'
                            }
                        }
                    },

                    colors: ['#F15C80', '#8085E9', '#F7A35C', '#80A35C', '#2B908F', '#e1e1ef', '#983422', '#a32020'],

                    plotOptions: {
                        series: {
                            pointStart: new Date().getFullYear()
                        },
                        column: {
                            stacking: 'normal',
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: ["viewFullscreen", {
                                    text: 'Toggle Legends',
                                    onclick: function () {
                                        var legend = chart.legend;
                                        if (legend.display) {
                                            // Hide the legend
                                            chart.legend.update({enabled: false});
                                            localStorage.setItem('chartLegends', JSON.stringify(false))

                                        } else {
                                            // Show the legend
                                            chart.legend.update({enabled: true});
                                            localStorage.setItem('chartLegends', JSON.stringify(true))
                                        }
                                    }
                                }, {
                                    text: 'Toggle Stack Labels',
                                    onclick: function () {
                                        var current = this.yAxis[0].options.stackLabels.enabled;
                                        this.update({
                                            yAxis: {
                                                stackLabels: {
                                                    enabled: !current
                                                }
                                            }
                                        });
                                        localStorage.setItem('chartStackLabels', JSON.stringify(!current))
                                    }
                                }, "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF"]
                            },

                        }
                    },
                    
                    credits: {
                        enabled: false
                    },

                    series: chartData.value,

                    tooltip: {                
                        formatter: function() {
                            const totalBudget: number = getXBudget(this.point.index) as number;
                            const remainingBudget: number = totalBudget - (this.total as number);

                            if (this.point.series.name == $t('Budget')) {
                                return `
                                    <b>${$t('Budget')} ${this.x}:</b> ${Highcharts.numberFormat(totalBudget, 0)}<br/>
                                `;
                            }

                            if(this.point.series.name == $t('Forecast Cost Projects')) {
                                return `
                                    <b>${$t('Forecast Cost Projects')} ${this.x}:</b> ${Highcharts.numberFormat(this.y, 0)}<br/>
                                `;
                            }
                            
                            return `
                                <b> ${this.series.name}</b><br/>
                                <b>${this.x}:</b> ${Highcharts.numberFormat(this.y, 0)} <br/>
                                <br/>
                                <b>${$t('Budget for')} ${this.x}:</b> ${Highcharts.numberFormat(totalBudget, 0)}<br/>
                                <b>${$t('Allocated')}:</b> ${Highcharts.numberFormat(this.total, 0)}<br/>
                                <b>${$t('Remaining')}:</b> ${Highcharts.numberFormat(remainingBudget, 0)}
                            `;
                        }
                    },

                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
        };
            chart = Highcharts.chart('chartContainer', mergedOptions);
            

            } else {
                throw new Error('Chart container not found');
            }
        } catch (error) {
            console.error('Failed to create chart:', error);
            errorMessage.value = 'Failed to create chart.';
        }
    });
};


const loadAndUpdateChart = async () => {
    errorMessage.value = null; // Clear any previous errors
    isLoading.value = true; // Started loading

    try {
        local_projects.recordSource.whereClause = filter.value;
        await local_projects.load();
        const projects = local_projects.data.map(record => record.OrgUnit_ID);

        if (projects.length === 0 && ids.value.length === 0) {
            errorMessage.value = 'No projects or IDs available.';
            chartData.value = [{ type: 'line', name: 'No project data', data: [] }];
            createChart(); // No need for await here
            return;
        }

        if (ids.value.length > 0) {
            local_ProjectsForecasts.recordSource.whereClause = `OrgUnit_ID IN (${ids.value.join(', ')})`;
        } else {
            local_ProjectsForecasts.recordSource.whereClause = `OrgUnit_ID IN (${projects.join(', ')})`;
        }

        await local_ProjectsForecasts.load();
        await createDataSet(); 
        createChart(); // No need for await here
    } catch (error) {
        console.error('Error loading chart data:', error);
        errorMessage.value = 'Failed to load chart data.';
    }
};


// Watch for changes and load data
watch([filter, ids], loadAndUpdateChart, { immediate: true, deep: true });

// Create dataset based on data loaded
const createDataSet = async() => {
    const vData = local_ProjectsForecasts.data;
    const yearSums = new Array(mode.value).fill(0);

    chartData.value = vData.map(project => {
        const data = [];
        for (let i = 1; i <= mode.value; i++) {
            const yearProperty = `Year${i}`;
            if (project.hasOwnProperty(yearProperty)) {
                data.push(project[yearProperty]);

                if (project.UseCostData) {
                    yearSums[i - 1] += project[yearProperty];
                }
            }
        }
        return { type: 'column', name: project.NameAndTitle, data, color: project.UseCostData ? "#444444" : "" };
    });

    // Sort and update chart with new dataset
    chartData.value.sort((a, b) => a.color === "#444444" ? 1 : -1);
    if (yearSums.some(value => value !== 0)) {
        chartData.value.push({ type: 'line', name: 'Forecast Cost Projects', data: yearSums, color: '#33cc33' });
    }
    if (budgetData.value.length > 0) {
        chartData.value.push({ type: 'line', name: 'Budget', data: budgetData.value, color: '#f7202c' });
    }
};

// Mounted hook to initialize data
onMounted(async () => {
    try {
        await local_budget.load();
        await loadAndUpdateChart();
    } catch (error) {
        console.error('Error on initial load:', error);
        errorMessage.value = 'Initial data loading failed.';
    }
});
</script>

<style scoped>
.spinner-container,
.chart-container {
  position: relative; /* Ensure both elements stay in the same space */
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.spinner-container {
  position: absolute; /* Spinner overlaid on top */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center it */
  z-index: 10; /* Ensure it's above the chart */
  opacity: 0;
  visibility: hidden;
}

.spinner-container.active {
  opacity: 1;
  visibility: visible;
}

.chart-container {
  width: 100%;
  height: 100%; /* Set fixed or dynamic height to avoid container collapse */
  opacity: 0;
  visibility: hidden;
}

.chart-container.active {
  opacity: 1;
  visibility: visible;
}

</style>
